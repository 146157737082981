import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { get, first } from 'lodash'
import Layout from '../components/layout'
import HeroImage from '../components/heroImage'
import Paragraphs from '../components/helpers/paragraphs'
import { getDataForLanguage } from '../utils/getDataForLanguage'

export class OurStoryPage extends Component {
    render() {

        const allPageData = get(
            this,
            "props.data.allContentfulOurStoryPage.edges",
            ""
        )
        let pageData = first(getDataForLanguage(
            allPageData.map(item => item.node),
            process.env.COUNTRY
        ))
        let heroImageAndTextData = allPageData.map(item =>
            get(item, "node.heroImageAndText", item)
        )
        let transHeroImageAndTextData = first(
            getDataForLanguage(heroImageAndTextData, process.env.COUNTRY)
        )

        return (
            <Layout
                title={get(pageData, 'metaTitle','')} 
                description={get(pageData, 'metaDescription','')}
                image={get(pageData, 'image.file.url', '')}
                pathName="/our-story"
            >
                {transHeroImageAndTextData &&
                    <HeroImage entity={transHeroImageAndTextData} />
                }

                <div className="our-story-page" id="maincontent">
                    <div className="container-sm">
                        <h1 className="page-title page-title--second text-center">{get(pageData, 'title', '')}</h1>
                        <h2 className="page-subheader">{get(pageData, 'subheader.subheader', '')}</h2>

                        <div className="page-body">
                            <img className="page-image" alt={get(pageData, 'image.title', 'Main image - Our Story')} src={get(pageData, 'image.file.url', '')} />
                            <Paragraphs value={get(pageData, 'body', '')} />
                        </div>
                        <div className="page-body page-body--second">
                            <Paragraphs value={get(pageData, 'secondBody', '')} />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default OurStoryPage

export const pageQuery = graphql`
  query OurStoryPageQuery {
    allContentfulOurStoryPage {
      edges {
        node {
          node_locale
          metaTitle
          metaDescription
          title
          subheader {
            subheader
          }
          image {
            file {
              url
            }
            title
            node_locale
            contentful_id
          }
          body {
            body
          }
          secondBody {
            secondBody
          }
          heroImageAndText {
            title
            linkPath
            linkText
            description
            backgroundImage {
              file {
                url
              }
              title
              node_locale
              contentful_id
            }
            node_locale
          }
          node_locale
          contentful_id
        }
      }
    }
  }
`