import React, { useState } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import ShareYourStory from './shareYourStory';

function HeroImage(props) {

    const [ open, setOpen ] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };
     
    const onCloseModal = () => {
        setOpen(false);
    };

    const entity = get(props, 'entity', '');

    if (entity) {
        return (
            <>
                <div className="hero-image">
                    <img src={get(entity, 'backgroundImage.file.url','')} alt={get(entity, 'backgroundImage.title','Hero image')}/>
                    <div className="hero-image__content">
                        <div className="hero-image__title">{get(entity, 'title','')}</div>
                        <div className="hero-image__description">{get(entity, 'description','')}</div>
                        {get(entity, 'linkText', false) && 
                            <div className="hero-image__link">
                                <span onClick={()=> onOpenModal()} className="btn btn--primary btn--green" tabIndex="0">
                                {get(entity, 'linkText','')}
                                </span>
                            </div>
                        }
                    </div>
                </div>
                <ShareYourStory open={open} onClose={onCloseModal} />
        </>
        )
    }
    return(null);
}

HeroImage.propTypes = {
    openModal: PropTypes.any
}

export default HeroImage

