import React from 'react'
import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'

const ShareYourStory = ({open, onClose}) => {

    const bg = {
        overlay: {
            backgroundColor: "rgb(0, 0, 0, 0.95)"
        }
    };

    return (
        <Modal open={open} onClose={onClose} center className="modal" styles={bg}>
            <form className="contact-form--modal" name="Share Your Story Form" method="POST" data-netlify="true" action="/success">
                <h1 className="contact-form--modal__title">Share Your Story</h1>
                <div className="contact-form--modal__description">
                    Have you found relief with O’Keeffe’s? Share your story with us!
                </div>
                <input type="hidden" name="form-name" value="Share Your Story Form" />
                <div className="field">
                    <label htmlFor="share-name">Name</label>
                    <input type="text" name="name" id="share-name" required />
                </div>
                <div className="field">
                    <label htmlFor="share-email">Email</label>
                    <input type="email" name="email" id="share-email" required />
                </div>
                <div className="field">
                    <label htmlFor="share-phone">Phone</label>
                    <input type="text" name="phone" id="share-phone" />
                </div>
                <div className="field">
                    <label htmlFor="share-message">Story</label>
                    <textarea name="message" id="share-message" />
                </div>
                <div className="field stacked">
                    <label htmlFor="share-agree">I agree to have my testimonial shared on social media</label>
                    <input type="checkbox" name="share" id="share-agree" checked="false" value="yes" />
                </div>
                <div data-netlify-recaptcha="true"></div>
                <button type="submit" className="btn btn--primary">Submit</button>
            </form>
        </Modal>
    )
}

ShareYourStory.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.any
}

export default ShareYourStory